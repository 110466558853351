.dataimg-alarmclock {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/alarmclock.svg"); background-repeat: no-repeat;}

.dataimg-anti-aging {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/anti-aging.svg"); background-repeat: no-repeat;}

.dataimg-arrow-left {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/arrow-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-left {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/arrow-light-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-right {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/arrow-light-right.svg"); background-repeat: no-repeat;}

.dataimg-arrow-right {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/arrow-right.svg"); background-repeat: no-repeat;}

.dataimg-arrow-thin {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/arrow-thin.svg"); background-repeat: no-repeat;}

.dataimg-arrow {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/arrow.svg"); background-repeat: no-repeat;}

.dataimg-book {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/book.svg"); background-repeat: no-repeat;}

.dataimg-check {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/check.svg"); background-repeat: no-repeat;}

.dataimg-child {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/child.svg"); background-repeat: no-repeat;}

.dataimg-clock-news {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/clock-news.svg"); background-repeat: no-repeat;}

.dataimg-clock {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/clock.svg"); background-repeat: no-repeat;}

.dataimg-cross-thin {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/cross-thin.svg"); background-repeat: no-repeat;}

.dataimg-cross {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/cross.svg"); background-repeat: no-repeat;}

.dataimg-disease {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/disease.svg"); background-repeat: no-repeat;}

.dataimg-double-arrow {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/double-arrow.svg"); background-repeat: no-repeat;}

.dataimg-facebook {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/facebook.svg"); background-repeat: no-repeat;}

.dataimg-hubnuti {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/hubnuti.svg"); background-repeat: no-repeat;}

.dataimg-injection {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/injection.svg"); background-repeat: no-repeat;}

.dataimg-leaf {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/leaf.svg"); background-repeat: no-repeat;}

.dataimg-loading {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/loading.svg"); background-repeat: no-repeat;}

.dataimg-login {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/login.svg"); background-repeat: no-repeat;}

.dataimg-logo-icon {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/logo-icon.svg"); background-repeat: no-repeat;}

.dataimg-logo-light {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/logo-light.svg"); background-repeat: no-repeat;}

.dataimg-logo {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/logo.svg"); background-repeat: no-repeat;}

.dataimg-man {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/man.svg"); background-repeat: no-repeat;}

.dataimg-online-live {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/online-live.svg"); background-repeat: no-repeat;}

.dataimg-online {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/online.svg"); background-repeat: no-repeat;}

.dataimg-pencil {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/pencil.svg"); background-repeat: no-repeat;}

.dataimg-person {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/person.svg"); background-repeat: no-repeat;}

.dataimg-play {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/play.svg"); background-repeat: no-repeat;}

.dataimg-priznaky {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/priznaky.svg"); background-repeat: no-repeat;}

.dataimg-profile {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/profile.svg"); background-repeat: no-repeat;}

.dataimg-psychika {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/psychika.svg"); background-repeat: no-repeat;}

.dataimg-search {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/search.svg"); background-repeat: no-repeat;}

.dataimg-sthetoscope {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/sthetoscope.svg"); background-repeat: no-repeat;}

.dataimg-twitter {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/twitter.svg"); background-repeat: no-repeat;}

.dataimg-woman {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/woman.svg"); background-repeat: no-repeat;}

.dataimg-bylinky {background-image: url("https://img2.cncenter.cz/images/moje-zdravi/dist/svg/bylinky.svg?v=2"); background-repeat: no-repeat;}
